<template>
  <section class="verifyOtp">
    <h1><span></span> Verification code <v-icon> mdi-close </v-icon></h1>
    <p>Please type the verification code sent to your regestered email.</p>
    <v-form ref="forgetPasswordFormOTP">
      <PincodeInput v-model="otp" :length="6" placeholder="-" />
      <div class="mt-4">
        <v-btn @click="validate" large v-if="!loading"
          >Verify <v-icon> mdi-chevron-double-right </v-icon></v-btn
        >
        <v-btn loading large v-if="loading"></v-btn>
      </div>

      <p class="mt-3">
        <span class="d-block pb-3">Don't receive the OTP?</span>
        <v-btn v-if="!loadingOtp" @click="getOtpAction()">Resend OTP</v-btn>
        <v-btn v-if="loadingOtp" loading></v-btn>
      </p>
    </v-form>
    <v-snackbar v-model="snackbar">{{ message }} </v-snackbar>
  </section>
</template>

<script>
import PincodeInput from "vue-pincode-input";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "OTPVerify",
  data: () => ({
    showpassword: false,
    otp: "",
    otpRule: [(v) => !!v || "OTP is required."],
    rules: {
      required: (value) => !!value || "Password is required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
    loading: false,
    message: "",
    snackbar: false,
    loadingOtp: false,
  }),
  components: {
    PincodeInput,
  },
  computed: {
    ...mapGetters(["allRegister"]),
  },
  methods: {
    ...mapActions(["postVerify", "getOTP"]),
    async validate() {
      this.loading = true;
      let data = {};
      if (this.allRegister != undefined) {
        data.email = this.allRegister.data.results.email;
      }
      data.otp = this.otp;
      await this.postVerify(data);
      if (this.allRegister.status == 200) {
        if (this.$route.name != "LoginUser") {
          this.$router.push("/login");
        }
        this.snackbar = true;
        this.message = this.allRegister.data.message;
      } else {
        this.snackbar = true;
        this.message = this.allRegister;
      }
      this.loading = false;
    },
    async getOtpAction() {
      this.loadingOtp = true;
      await this.getOTP({ email: sessionStorage.getItem("email") });
      this.message = "Please check your email for OTP Code";
      this.snackbar = true;
      this.loadingOtp = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.verifyOtp {
  background: #fff;
  width: 500px;
  padding: 30px 20px;
  text-align: center;
  margin: 10% auto;
  h1 {
    font-size: 40px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }
  .v-btn {
    background: #0878d4 !important;
    width: 100%;
    min-height: 50px;
  }
  p {
    font-size: 14px;
  }
}
.v-btn {
  color: #fff;
}
</style>